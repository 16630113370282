<template>


  <v-app v-window-resize="GET_WINDOW_WIDTH">

    <portal-target name="debug"></portal-target>
    <clippingMasks  />

    <portal-target name="dialog"></portal-target>
    <portal-target name="dialog_top"></portal-target>

    <notification></notification>
    <portal-target name="loading_line"></portal-target>
    <span v-show="false" id="virtual_hidden"></span>

    <specator-controls v-if="$store.state.auth.isSpectator" />

    <router-view :class="[{hideContent : SM && $store.state.MOBILE_FULLSCREEN}]" :key="$store.state.auth.spectatorTrigger"></router-view>

    <wsOnboardWrapper v-if="$store.state.onboarding.displayWindow"  />



  </v-app>

</template>

<script>

import {mapActions, mapMutations} from "vuex";
import notification from "@/components/UI/notification";
import wsOnboardWrapper from "@/onboardTours/UI/wsOnboardWrapper";
import specatorControls from "@/components/auth/specatorControls";
import clippingMasks from "@/components/AvalonEditor/UI/clippingMasks";


export default {
  name: 'App',
  title : 'Fine Trading',
  metaInfo() {
    if ( !this.IS_ROUTE_CHANGED )  {
      return {}
    }
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t('StudyPlatform')  ,
      // all titles will be injected into this template
      titleTemplate: '%s | WeStudy ™ ',
    }
  },
  components: {
    notification,
    wsOnboardWrapper,
    specatorControls,
    clippingMasks
  },
  methods: {

    ...mapActions('auth',['GET_USER' , 'GET_PROCEED_USER' ]),
    ...mapActions(['INIT_SOCKET']),
    ...mapMutations('auth',['LOGOUT']),
    ...mapActions('ajax', [ 'REFRESH_TOKEN']),

    shiftDown(e){
      if ( e.key === "Shift" ) {
        this.$store.state.shiftDown = true
      }
    },
    shiftUp(e){
      if ( e.key === "Shift" ) {
        this.$store.state.shiftDown = false
      }
    },
    async CHECK_TOKEN() {
      if (!this.$store.state.auth.loggedIn) {
        if (localStorage.getItem('access_token')){
          this.$store.state.auth.accessToken = localStorage.getItem('access_token')

          await this.GET_USER( {
            token: localStorage.getItem('access_token') ,
            router : {
              router :this.$router,
              path   :this.$route.path
            },
            redirect : false,
          })
        }
      }
    },
    WRONG_USER_BUSINESS_ACTION() {
      // Wrong user business action - logout and store old business to local data
      localStorage.setItem('old_business' , localStorage.getItem('business') )
      localStorage.setItem('old_refresh_token' , localStorage.getItem('refresh_token') )
      this.$store.state.auth.proceedUser = this.COPY(this.$store.state.auth.user)
      this.LOGOUT()
    },

    checkAgent() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.$store.state.AGENT_SOCIAL_APP =  /Instagram/.test(userAgent) || /FBAN/.test(userAgent) || /FBAV/.test(userAgent);
      this.$store.state.MI_BROWSER_APP = /MiuiBrowser/i.test(userAgent);
    }
  },
  watch : {
    async CURRENT_BUSINESS(value , old) {
      if ( this.$store.state.auth.mainDashboardRequest) {
        return
      }
      if ( old !== value ) {

        if ( this.$store.state.auth.loggedIn ) {
          if ( !this.$store.state.auth.user.business_host && !this.IS_BUSINESS_OWNER )  {
           this.WRONG_USER_BUSINESS_ACTION()
          } else if ( this.$store.state.auth.user.business_host && this.$store.state.auth.user.business_host !== value ) {
            this.WRONG_USER_BUSINESS_ACTION()
          }
        }
        localStorage.setItem('business' , value )

        if ( !this.$store.state.auth.loggedIn && localStorage.getItem('business') === localStorage.getItem('old_business')) {
          if (  localStorage.getItem('old_refresh_token') ) {
            // Return to previous school, refresh old business user token
            localStorage.setItem('refresh_token' , localStorage.getItem('old_refresh_token') );
            await this.REFRESH_TOKEN()
            await this.GET_USER( {
              token: localStorage.getItem('access_token') ,
              router : {
                router :this.$router,
                path   :this.$route.path
              },
              redirect : false,
            })
          }
        } else if ( !this.$store.state.auth.loggedIn
            && !this.$store.state.auth.proceedUser.email
            && !!localStorage.getItem('old_business')
            && !!localStorage.getItem('old_refresh_token')
            && localStorage.getItem('business') !== localStorage.getItem('old_business')) {
          // Get proceed user data, if page was reloaded
          this.$store.state.auth.proceedUser = await this.GET_PROCEED_USER({refresh : localStorage.getItem('old_refresh_token')}) || {}
          // need to get user for proceed user
         // this.$store.state.auth.proceedUser = this.COPY(this.$store.state.auth.user)

        }

      }
    }
  },
  async beforeMount() {
    this.checkAgent()
    this.$store.state.$NEW_AVALON = process.env.VUE_APP_AVALON === 'new'
    this.GET_WINDOW_WIDTH()
    await this.CHECK_TOKEN()
  },
  async created() {
    this.$store.commit('GET_QUERY_PARAMS')
    setTimeout(this.GET_WINDOW_WIDTH , 200)

    if ( !location.host.includes('localhost') ) {
      this.$store.state.appUrl = "https://" + location.host
    } else {
      this.$store.state.appUrl = "http://" + location.host
    }

    window.addEventListener('keydown', this.shiftDown )
    window.addEventListener('keyup', this.shiftUp )

    // DASHBOARD PAGE UPDATE
    if ( this.$route.path.includes('dashboard') ) {
     if (this.$route.params.alias ) {
       this.$store.state.business.selectedBusiness.alias = this.$route.params.alias
     }
    }

    this.INIT_SOCKET(this.$socket)
    let host = window.location.host;
    this.$store.state.FRONT_LOCATION = host

    this.$store.state.domain =  !([this.$store.state.hostname,this.$store.state.second_hostname].includes(host) ||
        ['www.' + this.$store.state.hostname,'www.' + this.$store.state.second_hostname].includes(host)
    );
    if ( location.host.includes('192.168.1.231') ) {
      this.$store.state.domain = false
    }


    if ( !this.$store.state.domain ) {
      if (this.$route.params.alias ) {
        this.$store.state.auth.rootPlace = this.$route.params.alias
      }
    }

    await this.CHECK_INVITATION_CODE()




  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;1,300&display=swap');
@import '~shepherd.js/dist/css/shepherd.css';

.v-application--wrap {
  min-height: unset !important;
  position: absolute !important;
  width: 100%;
  height: 100%;
  background-color: #242424;
}
.hideContent {
  display : none
}

:root {
  --wsACCENT           : #567186;
  --wsDARK             : #567186;
  --wsMIDDLE           : #4F5A6E;
  --wsDARKER           : #283E4F;
  --wsDARKLIGHT        : #94b3c7;
  --wsBACKGROUND       : #DEE8EE;
  --wsLIGHTACCENT      : #D7E5F0;
  --wsLIGHTCARD        : #F2F8FB;
  --wsSUCCESS          : #7AB971;
  --wsLIGHTSUCCESS     : #DDF5CD;
  --wsATTENTION        : #ED8A58;
  --wsWARNING          : #CF0C47;
  --wsDASHBACKGROUND   : #DCE8EF;
  --wsSUCCESSDARK      : #44655b;
  --wsWHITE            : #ffffff;
  --wsWHITETEXT        : #ffffff;
  --pink_1             : #393c65 ;
  --pink_2             : #262443 ;
  --pink_3             : #4b5797 ;
  --pink_4             : #1d1d36 ;
  --green_1            : #7AB971 ;
  --green_2            : #44655b ;
}

.wsACCENT {
  color :  var(--wsACCENT) !important;
}
.wsATTENTION {
  color :  var(--wsATTENTION) !important;
}
.wsDARKER {
  color :  var(--wsDARKER) !important;
}
.wsDARKLIGHT {
  color :  var(--wsDARKLIGHT) !important;
}
.WHITE {
  color : var(--wsWHITE);
}
.wsWHITE {
  color : var(--wsWHITE);
}
.wsWHITETEXT {
  color : var(--wsWHITETEXT);
}
.wsMIDDLE {
  color : var(--wsMIDDLE);
}


.grabbingCursor {
  cursor: url(https://cloud.cs2.westudy.ua/file?path=westudy_dev1/public/tesla/460457bd-208a-4146-9336-58451c16c81f.png),move !important;
  cursor: url(https://cloud.cs2.westudy.ua/file?path=westudy_dev1/public/tesla/460457bd-208a-4146-9336-58451c16c81f.png) 16 16,move !important;
}
.defaultCursor {
  cursor : default
}

h1,h2,h3,h4,h5,h6 {
  font-family: "AmbulatoriaD" ;
  font-weight: 600;
}

#app {
  font-weight: 300;
  background-color: #242424;
  font-family: "AmbulatoriaD" ;
}

.nunito {
  font-family: "Nunito";
}
//.nunito {
//  h1,h2,h3,h4,h5,h6 {
//    font-family: "Nunito";
//  }
//}

.fill-height {
  height: 100% !important;
  min-height: 100% !important;
}
.grow {
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
}
.shrink {
  flex-grow: 0 !important;
  flex-shrink: 1 !important;
}

.shorten-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
}
.shorten-text-two {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.shorten-text-three {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.noUnderline {
  text-decoration: none;
}

.wsHoverLight tbody tr:hover {
  background-color : #F1F8FB !important
}
.wsHoverLightTr:hover {
  background-color : #F1F8FB !important
}
.wsRoundedLight {
  border-radius: 4px !important;
}
.wsRoundedSemi {
  border-radius: 8px !important;
}
.wsRoundedLightLeft {
  border-radius: 8px 0 0 8px;
}
.wsRoundedLightRight {
  border-radius: 0 8px 8px 0;
}
.wsRoundedLightTopSemi {
  border-radius: 8px 8px 0 0;
}
.wsRoundedLightBottom {
  border-radius: 0 0 4px 4px;
}
.wsRounded  {
  border-radius: 30px !important;
}
.wsRoundedTop  {
  border-top-right-radius: 30px !important;
  border-top-left-radius: 30px !important;
}
.wsRoundedHalfTop {
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}
.wsRoundedLeft {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.wsRoundedLeft {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}
.wsRoundedHalf  {
  border-radius: 15px !important;
}
.wsRoundedHalf-2  {
  border-radius: 20px !important;
}

.wsRoundedHalfBottom  {
  border-bottom-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}
.wsRoundedHalfRight {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
.wsRoundedHalfLeft {
  border-radius: 16px 0 0 16px;
}

.wsRoundedHalfTopRight {
  border-top-right-radius: 15px !important;
}
.wsRoundedHalfTopLeft {
  border-top-left-radius: 15px !important;
}

.section-content {
  max-width   : 1100px;
  width       : 100%;
  margin      : auto;
}

.pointer {
  cursor: pointer !important;
}

.noCaps {
  text-transform: none !important;
  letter-spacing: unset !important;
  font-weight: 600 !important;
}

.compact-form {
  transform: scale(0.875);
  transform-origin: center;
}
.finetTable  {
table {
  border-radius: 0 !important;
}
  thead {
    border-radius: 0 !important;
    tr {
      border-radius: 0 !important;
      td {
        border-radius: 0 !important;
      }
    }
    border-radius: 0 !important;
    td {
      border-radius: 0 !important;
    }
  }
td {
  border-radius: 0 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #393939;
}
tr { transition: all .3s ease; }
tr:hover {
  background-color: #459ad2 !important;
  color:white !important;
  border-radius: 0;
  cursor : pointer;
  i{
    color:white !important;
    transition: all .3s ease;
  }
td { color: white;
div span {
  color: white !important;
  border-color : white ! important;
  transition: all .3s ease;
}
}
}




}
.fineBorder {
  border : 2px solid #dddddd;
  border-radius : 5px;
  transition: all .3s ease;
}
.toggle {
  font-size: 12px;
  font-weight: 400;
}
.toggle_item {
  border : 2px solid #dddddd;
  border-radius : 5px;
  transition: all .3s ease;
}
.toggle_item_active {
  background-color: #585858;
  border : 2px solid #585858;
  color : white;
}
.toggle_item:hover {
  background-color: #585858;
  border : 2px solid #585858;
  color : white !important;
  cursor: pointer;
}
.editWindow {
  border : 1px #ffffffbb dashed;
}
.editWindow:hover {
  background-color: #ffffff55;
  cursor: pointer;
}
.linkHover:hover {
  text-decoration: underline !important;
  cursor: pointer !important;
}
.underline {
  text-decoration: underline !important;
}
.hover-underline:hover {
  text-decoration: underline !important;
}

.positionAbsolute {
  position: absolute !important;
}

.positionRelative {
  position: relative !important;
}

.editorStyle {
  outline: none !important;
  resize: none !important;
}
.editorStyle:read-only {
  outline: none !important;
  resize: none !important;
}
.readOnlyCursor:read-only {
  cursor: default;
}


.wsInput input::placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
}
.wsInput {
  font-size: 14px !important;
  font-weight: 400 !important;
}
*.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.xxx-enter-active {
  transition: opacity .3s ease-in-out;
}

.xxx-leave-active {
  transition: none;
}

.xxx-enter, .xxx-leave, .xxx-leave-to {
  opacity: 0;
}

.xxx-enter-to {
  opacity: 1;
}

.ws-loading {
  width: 100%;
  height: 100px;
  background: linear-gradient(90deg, transparent, #56718655  , transparent );
  background-size: 200% 100%;
  animation: gradientMove 1.8s linear infinite;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 200% 50%;
  }

}

/* 1. declare transition */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
  position: absolute;
}



//::-webkit-scrollbar{height:4px;overflow:visible;width:4px; position: absolute;}
//::-webkit-scrollbar-button{height:0;width:0}
//::-webkit-scrollbar-track{background-clip:padding-box;box-shadow:inset 0px 0 0 #e6e6e6}
//::-webkit-scrollbar-track:hover{background-color:transparent;}
//::-webkit-scrollbar-track:active{background-color:transparent; cursor: all-scroll}
//::-webkit-scrollbar-thumb{background-color:#94b3c7;min-height:28px;padding:100px 0 0;}
//::-webkit-scrollbar-thumb:hover{background-color:#567186;}
//::-webkit-scrollbar-thumb:active{background-color:#567186;}




</style>
