const state = {
    selectedElement : {},
    selectedBlock : {},
    selectedColumnIndex : null,

    defaultNewsletterConfig : {},
    newsletterConfig : {},
    businessConfig : {},

    blockEditTrigger : 0,
    elementEditTrigger : 0,

    newBlockPosition : null,
    newBlockId : null,
    elementHover : null,
    navigation : 'general',
    mobileView : false,

    displayTestEmailTrigger : 0,
    triggerSendNewsletter : 0,
}

const actions = {

    // SENDING FUNCTIONS


    async START_NEWSLETTER({dispatch,rootState} , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/start/${body}`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async SEND_TEST_EMAIL({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/send/test`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },



    // BUSINESS CONFIG

    async ADD_EDIT_BUSINESS_EMAIL_CONFIG({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/config`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },

    // NEWSLETTERS

    async GET_NEWSLETTERS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletters`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async ADD_EDIT_NEWSLETTER({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },

    async GET_NEWSLETTER({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async GET_NEWSLETTER_STATS({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/stats/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // DESIGN

    async ADD_EDIT_DESIGN({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/design`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async ADD_DESIGN_TEMPLATE({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/template/design`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },



    async GET_NEWSLETTER_DESIGN({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/design/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async GET_NEWSLETTER_DESIGN_TEMPLATES({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/templates/design`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // BLOCKS

    async ADD_EDIT_DESIGN_BLOCK({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/design/block`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async MOVE_DESIGN_BLOCK({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/design/move_block`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async GET_NEWSLETTER_DESIGN_BLOCK({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/design/block/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async GET_NEWSLETTER_BLOCKS_TEMPLATES({dispatch,rootState} ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/templates/blocks`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async DELETE_NEWSLETTER_DESIGN_BLOCK({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/design/block/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // ELEMENT

    async ADD_EDIT_DESIGN_ELEMENT({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/design/element`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async MOVE_DESIGN_ELEMENT({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/design/move_element`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async DELETE_DESIGN_ELEMENT({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/design/element/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },



    // RECEIVERS GROUPS


    async ADD_RECEIVERS_GROUP({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/receivers_group`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async DELETE_RECEIVERS_GROUP({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`finemailer/newsletter/receivers_group/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },





}
const mutations = {

    FLUSH_DATA(state) {
        state.selectedElement = {}
        state.selectedBlock = {}
        state.selectedColumnIndex = null
        state.blockEditTrigger = 0
        state.elementEditTrigger = 0
        state.newBlockId = null
        state.navigation = 'general'
    }

}

export default {
    namespaced:true,
    actions,
    mutations,
    state
}